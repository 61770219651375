<template>
  <a-card>
    <a-tabs @change="onChangeTabs" v-model="tabActive">
      <a-tab-pane key="author" tab="帐号总览">
        <colCard
            :count-data="countData"
        />
        <div class="flex-wrap">
          <a-button class="ml-10p" type="primary" @click="handleOpen(1)">配置导出模板</a-button>
          <a-button class="ml-10p" type="primary" @click="handleOpen(2)">全部数据导出</a-button>
        </div>
        <tabPageModal
            ref="authorTab"
            :timeLabel="'更新时间'"
            tabs="author"
            :groupList="groupList"
            :columns="columnsAccount"
            :dataList="dataList"
            :countData="countData"
            :isLoading="isLoading"
            :activityName="activity_name"
            :pagination="pagination"
            :is-form="'details'"
            @resetList="resetList"
            @searchList="searchList"
            @exportExcel="exportExcel"
            @onChange="onChange"
        ></tabPageModal>
      </a-tab-pane>
      <a-tab-pane key="KPI" tab="KPI详情">
        <kpiPage
            ref="KPITab"
            tabs="KPI"
            :list="KPIList"
            :pagination="pagination"
            :activityId="activity_id"
            :principalId="principal_id"
            :isLoading="isLoading"
            @searchKPIList="searchKPIList"
            @resetKPIList="resetKPIList"
            @onChange="onChangePaginationKPIList"
        ></kpiPage>
      </a-tab-pane>
    </a-tabs>

    <!--数据导出时间选择-->
    <a-modal
        :visible="visible"
        title="全部数据导出"
        :width="600"
        :maskClosable="false"
        @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button
            @click="handleCancel"
        >取消
        </a-button>
        <a-button
            type="primary"
            :loading="is_submit"
            :disabled="is_submit"
            @click="exportExcelAll"
        >联合导出
        </a-button>
      </template>
      <a-form-model
          ref="dataForm"
          :model="dataForm"
          :rules="rules"
          v-bind="data_layout"
      >
        <a-form-model-item
            label="选择时间"
            prop="time"
        >
          <a-range-picker
              :placeholder="['开始时间', '结束时间']"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              inputReadOnly
              @change="onChangeDate"
              style="width:100%"
              v-model="dataForm.time"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import tabPageModal from "../../trainingList/components/tabPage";
import kpiPage from "../../trainingList/components/kpi_list";
import colCard from "./components/col_card";
import uploadExcel from "@/utils/uploadExcel.js";
import https from "@/api/kpi.js";
import buryEvent from "@/utils/buryEvent.js";
import moment from "moment";
import activityApi from '@/api/activity'

export default {
  name: "details",
  data() {
    return {
      tabActive: "author",
      activity_name: this.$route.query.activity_name || "",
      activity_id: this.$route.query.activity_id || "",
      principal_id: this.$route.query.principal_id || "",
      groupList: [],
      dataList: [],
      countData: {},
      isLoading: false,
      columnsAccount: [
        {
          scopedSlots: {customRender: "dataIndex"},
          title: "序号",
          width: 60
        },
        {
          dataIndex: "platform",
          scopedSlots: {customRender: "authorInfo"},
          title: "主播数据"
        },
        {
          dataIndex: "dealer_name",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "servie_auth_status",
          scopedSlots: {customRender: "statusRender"},
          title: "授权状态"
        },
        // {
        //   dataIndex: 'servie_auth_status',
        //   scopedSlots: {customRender: 'authorServie'},
        //   title: '创作者平台',
        //   filterType: 'isShow'
        // },
        // {
        //   dataIndex: 'open_auth_status',
        //   scopedSlots: {customRender: 'authorOpen'},
        //   title: '互动数据',
        //   filterType: 'isShow'
        // },
        //  {
        //   dataIndex: 'group_name',
        //   title: '分组'
        // },
        {
          dataIndex: "data_update_time",
          title: "更新时间"
        },
        {title: "操作", align: "center", scopedSlots: {customRender: "operationAccount"}, width: 150}
      ],
      searchFormData: {},
      pagination: {
        total: 0,
        current: 1,
        page_size: 10
      },
      KPIList: [],
      searchData: {},
      visible: false,
      is_submit: false,
      dataForm: {
        begin_time: moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00",
        end_time: moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59",
        time: [moment().subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00", moment().subtract(1, "days").format("YYYY-MM-DD") + " 23:59:59"]
      },
      data_layout: {
        labelCol: {span: 4},
        wrapperCol: {span: 20}
      },
      rules: {
        time: [
          {type: "array", required: true, message: "请选择时间", trigger: "change"}
        ]
      },
    };
  },
  components: {tabPageModal, kpiPage, colCard},
  mounted() {
    this.getGroupList();
    this.getDataList(this.searchFormData);
    this.getData(this.searchFormData);
  },
  methods: {
    onChangeTabs(val) {
      this.tabActive = val;
      switch(this.tabActive) {
        case "author":
          this.getDataList(this.$refs[`${ val }Tab`].searchForm);
          break;
        case "KPI":
          this.getKPIListList();
          break;
      }
    },
    getGroupList() {
      https.getGroupSelect({activity_id: this.activity_id}).then(res => {
        if(res.code === 200) {
          this.groupList = res.data || [];
        } else {
          this.groupList = [];
          this.$message.error("获取分组失败");
        }
      });
    },
    getData(searchData) {
      this.isLoading = true;
      let params_data = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });
      this.searchFormData = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });

      let params = {
        type: 1,
        data: params_data
      };
      this.$api.core.TrainingList.getData(params).then(res => {
        this.isLoading = false;
        if(res.code === 0) {
          this.countData = res.data || {};
        } else {
          // console.log("获取经销商媒体统计失败");
        }
      });
    },
    getKPIListList() {
      let params = {
        activity_id: this.activity_id,
        kpi_name: this.searchData.kpi_name,
        start_time: this.searchData.start_time,
        end_time: this.searchData.end_time,
        current: this.pagination.current || this.searchData.current,
        page_size: this.pagination.page_size || this.searchData.page_size,
      };
      https.getKPIListList(params).then(res => {
        if(res.code === 0) {
          this.KPIList = res.data.data || [];
          this.pagination.total = res.data.total;
        } else {
          this.KPIList = [];
          this.$message.error(res.msg || "获取经销商KPI详情失败");
        }
      });
    },
    getDataList(searchData) {
      // console.log(searchData);
      this.isLoading = true;
      let type = "author";
      if(this.tabActive == "author") {
        searchData.begin_time = "";
        searchData.end_time = "";
        searchData.current = null;
        searchData.page_size = null;
      }
      let params_data = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });
      if(this.tabActive === "author") {
        type = "author";
      }
      this.searchFormData = Object.assign(searchData, {
        activity_id: this.activity_id,
        principal_id: this.principal_id
      });

      let params = {
        type: type || "author",
        data: params_data
      };
      this.$api.core.TrainingList.getAccountList(params).then(res => {
        this.isLoading = false;
        if(res.code === 0) {
          let liveTableList = (res.data.list || []).map(v => _.merge(v, {__LOADING__: false}));
          this.dataList = liveTableList;
          this.pagination = res.data.pagination || {
            total: 0,
            current: 1,
            page_size: 10
          };
        } else {
          this.dataList = [];
          // console.log("获取列表失败失败");
        }
      });
    },
    onChange(searchData) {
      this.getDataList(searchData);
    },
    async exportExcel(searchData) {
      try {
        let data = Object.assign(searchData, {
          activity_name: this.activity_name,
          activity_id: this.activity_id,
          principal_id: this.principal_id
        });

        await activityApi.exportAuthor(data)
      } catch (e) {
        console.error(e)
      }
    },
    resetList(searchData) {
      this.getDataList(searchData);
      this.getData(searchData);
    },
    searchList(searchData) {
      this.getDataList(searchData);
      this.getData(searchData);
    },
    searchKPIList(searchData) {
      this.searchData = searchData;
      this.getKPIListList();
    },
    resetKPIList(resetData) {
      this.searchData = resetData;
      this.getKPIListList();
    },
    onChangePaginationKPIList(resetData) {
      // console.log(resetData);
      this.searchData = resetData;
      this.getKPIListList();
    },
    handleOpen(type) {
      switch(type) {
        case 1:
          this.$router.push({
            name: "configurationTemplate",
            query: {
              activity_id: this.activity_id,
              activity_name: this.activity_name,
              principal_id: this.principal_id
            }
          });
          break;
        case 2:
          this.visible = true;
          buryEvent("activity", "open-all-data-popup", this.activity_name);
          break;
      }
    },
    //关闭时间弹窗
    handleCancel() {
      this.visible = false;
    },
    //日期选择框
    onChangeDate(dates) {
      let begin_time = "";
      let end_time = "";
      if(dates[0]) {
        begin_time = this.$moment(dates[0]._d).format("YYYY-MM-DD HH:mm:ss");
        end_time = this.$moment(dates[1]._d).format("YYYY-MM-DD HH:mm:ss");
      }
      this.dataForm.end_time = end_time;
      this.dataForm.begin_time = begin_time;
    },
    exportExcelAll() {
      this.$refs.dataForm.validate(async (valid, obj) => {
        if(valid) {
          this.handleCancel();
          try {
            const data = {
              begin_time: this.dataForm.begin_time,
              end_time: this.dataForm.end_time,
              activity_name: this.activity_name,
              activity_id: this.activity_id,
              principal_id: this.principal_id
            };

            await activityApi.exportAll(data)
          } catch (e) {
            console.error(e)
          }
        }
      });
      buryEvent("activity", "dowload-all", this.activity_name);
    },
  }
};
</script>

<style scoped lang="less">
.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 25px;
  padding-top: 15px;
}

.ml-10p {
  margin-left: 10px;
}
</style>
