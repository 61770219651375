<template>
  <div>
    <a-spin :spinning="data_loading" tip="数据加载中">
      <div class="data-wrap">
        <div class="box">
          <div class="title">经销商数量</div>
          <div class="num">{{ countData.dealer_count | numCode }}</div>
        </div>
        <div class="box">
          <div class="title">媒体号数量</div>
          <div class="num">{{ countData.author_count | numCode }}</div>
        </div>
        <div class="box">
          <div class="title">创作者平台授权异常</div>
          <div class="num">{{ countData.p1_exception_count | numCode }}</div>
        </div>
        <div class="box">
          <div class="title">互动数据授权异常</div>
          <div class="num">{{ countData.p2_exception_count | numCode }}</div>
        </div>
        <div class="box">
          <div class="title">微信授权异常</div>
          <div class="num">{{ countData.unbind_weixin_count | numCode }}</div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: "col_card",
  data() {
    return {
      data_loading: false,
    };
  },
  props: {
    countData: {
      type: Object,
      default: () => {
      }
    }
  },
  mounted() {
  },
  methods: {}
};
</script>

<style scoped lang="less">
.data-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .box {
    width: 19%;
    padding: 8px;
    border-radius: 4px;

    &:nth-child(1) {
      background-color: rgb(240 245 255);

      .title:before {
        background: #86b0ff;
      }
    }

    &:nth-child(2) {
      background-color: rgb(238 251 245);

      .title:before {
        background: #3dcd9a;
      }
    }

    &:nth-child(3) {
      background-color: rgb(252 244 248);

      .title:before {
        background: #ff8ebd;
      }
    }

    &:nth-child(4) {
      background-color: #fffaf2;

      .title:before {
        background: #ffbf48;
      }
    }

    &:nth-child(5) {
      background-color: #fbf3fe;

      .title:before {
        background: #dd71ff;
      }
    }
  }

  .title {
    position: relative;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 600;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 7px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
    }
  }

  .num {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 22px;
  }
}
</style>
